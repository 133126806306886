// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'

import { SmallArrow } from 'src/components/svgs'

import { SEO } from 'src/components/seo'
import { RenderModules } from 'src/utils/renderModules'
import TiaIcon from 'src/images/tia-icon-without-ball.svg'
import classes from './page.module.css'
import cookie from 'js-cookie'
import Content from 'src/components/content'

export interface LocationProps {
  pageContext: {
    main: {
      modules: []
      slug: {
        current: string
      }
      splitTestingEnabled: boolean
      control: string
      variation: string
      title: string
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Location = ({ path, pageContext }: LocationProps) => {
  const {
    main: { modules, slug },
    meta,
    env,
  } = pageContext

  const url = path

  const lastVariant = cookie.get(`split-testing-${path}`)

  useEffect(() => {
    if (pageContext.main.splitTestingEnabled && typeof window !== 'undefined') {
      if (lastVariant) {
        window.location.href = lastVariant
      } else if (Math.random() < 0.5) {
        cookie.set(`split-testing-${path}`, pageContext.main.control, {
          expires: 30,
        })
        window.location.href = pageContext.main.control
      } else {
        cookie.set(`split-testing-${path}`, pageContext.main.variation, {
          expires: 30,
        })
        window.location.href = pageContext.main.variation
      }
    }
  }, [lastVariant, pageContext])

  return pageContext.main.splitTestingEnabled ? (
    <div className={classes.pageInner}>
      <img
        className={classes.bounce}
        src={TiaIcon}
        width="128px"
        height="68px"
        alt="tia logo"
      />
    </div>
  ) : (
    <>
      <SEO metaInfo={meta} pagePath={url} env={env} />
      <div className="bg-secondary-2">
        <Link
          to="/locations"
          className="container--l f jcs aic  mxa outer p1 back__link  bold"
        >
          <SmallArrow className="block rotate" /> All locations
        </Link>
      </div>
      <Content modules={modules}></Content>
    </>
  )
}

export default Location
